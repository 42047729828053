import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";

import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";

// import ExcelExport from "./ExportToExcel";

const WizardOrder = (props) => {
	const [csvArray, setCsvArray] = useState([]);

	useEffect(() => {
		console.log("props in order: ", props.manualMatches);
		let tempArray = [];

		if (props.manualMatches) {
			props.manualMatches.forEach((manual) => {
				tempArray.push(manual);
			});
		}

		if (props.data) {
			props.data.forEach((data) => {
				tempArray.push(data);
			});
		}

		setCsvArray(tempArray);
	}, []);

	return (
		<>
			<div className="process-column-header-with-actions">
				<h4>Please review the order.</h4>
				<CSVLink
					data={csvArray}
					filename={`order-${moment().format("MM-D-YY_hh-mm-ss")}`}
				>
					<Button variant="contained">
						{`Download order-${moment().format("MM-D-YY_hh-mm-ss")}.csv`}
					</Button>
				</CSVLink>
				{/* <ExcelExport
					data={props.data}
					fileName={`order-${moment().format("MM-D-YY_hh-mm-ss")}`}
				/> */}
			</div>
			<p>You can change the quantity in the column</p>
			<div className="row">
				<div className="processed-column">
					<TableContainer component={Paper} sx={{ maxHeight: 440 }}>
						<Table
							sx={{ minWidth: 650 }}
							size="small"
							aria-label="a dense table"
							stickyHeader
						>
							{props.data ? (
								<TableHead>
									<TableRow>
										<TableCell>Quantity</TableCell>
										<TableCell>Artikelnummer</TableCell>
										<TableCell align="right">Bezeichnung1</TableCell>
										<TableCell align="right">Bezeichnung2</TableCell>
										<TableCell align="right">Matchcode</TableCell>
										<TableCell align="right">Specials_dims</TableCell>
										<TableCell align="right">USER_ANSIOD1</TableCell>
										<TableCell align="right">USER_ANSIOD2</TableCell>
										<TableCell align="right">USER_ANSIWD1</TableCell>
										<TableCell align="right">USER_ANSIWD2</TableCell>
										<TableCell align="right">USER_ArtNrTeil1</TableCell>
										<TableCell align="right">USER_ArtNrTeil2</TableCell>
										<TableCell align="right">USER_Attest</TableCell>
										<TableCell align="right">USER_Ausfuehrung</TableCell>
										<TableCell align="right">USER_ENOD1</TableCell>
										<TableCell align="right">USER_ENOD2</TableCell>
										<TableCell align="right">USER_ENWD1</TableCell>
										<TableCell align="right">USER_ENWD2</TableCell>
										<TableCell align="right">USER_Produktgruppe</TableCell>
										<TableCell align="right">USER_TechnLV</TableCell>
										<TableCell align="right">USER_Werkstoff</TableCell>
									</TableRow>
								</TableHead>
							) : (
								""
							)}
							<TableBody>
								{props.manualMatches
									? props.manualMatches.map((rowManual) => {
											return (
												<TableRow
													key={rowManual.Artikelnummer}
													sx={{
														"&:last-child td, &:last-child th": {
															border: 0,
														},
													}}
												>
													<TableCell></TableCell>
													<TableCell component="th" scope="row">
														{rowManual.Artikelnummer}
													</TableCell>
													<TableCell align="right">
														{rowManual.Bezeichnung1}
													</TableCell>
													<TableCell align="right">
														{rowManual.Bezeichnung2}
													</TableCell>
													<TableCell align="right">
														{rowManual.Matchcode}
													</TableCell>
													<TableCell align="right">
														{rowManual.Specials_dims}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ANSIOD1}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ANSIOD2}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ANSIWD1}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ANSIWD2}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ArtNrTeil1}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ArtNrTeil2}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_Attest}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_Ausfuehrung}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ENOD1}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ENOD2}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ENWD1}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_ENWD2}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_Produktgruppe}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_TechnLV}
													</TableCell>
													<TableCell align="right">
														{rowManual.USER_Werkstoff}
													</TableCell>
												</TableRow>
											);
									  })
									: ""}
								{props.data
									? props.data.map((row) => (
											<TableRow
												key={row.Artikelnummer}
												sx={{
													"&:last-child td, &:last-child th": {
														border: 0,
													},
												}}
											>
												<TableCell>{row.quantity}</TableCell>
												<TableCell component="th" scope="row">
													{row.Artikelnummer}
												</TableCell>
												<TableCell align="right">{row.Bezeichnung1}</TableCell>
												<TableCell align="right">{row.Bezeichnung2}</TableCell>
												<TableCell align="right">{row.Matchcode}</TableCell>
												<TableCell align="right">{row.Specials_dims}</TableCell>
												<TableCell align="right">{row.USER_ANSIOD1}</TableCell>
												<TableCell align="right">{row.USER_ANSIOD2}</TableCell>
												<TableCell align="right">{row.USER_ANSIWD1}</TableCell>
												<TableCell align="right">{row.USER_ANSIWD2}</TableCell>
												<TableCell align="right">
													{row.USER_ArtNrTeil1}
												</TableCell>
												<TableCell align="right">
													{row.USER_ArtNrTeil2}
												</TableCell>
												<TableCell align="right">{row.USER_Attest}</TableCell>
												<TableCell align="right">
													{row.USER_Ausfuehrung}
												</TableCell>
												<TableCell align="right">{row.USER_ENOD1}</TableCell>
												<TableCell align="right">{row.USER_ENOD2}</TableCell>
												<TableCell align="right">{row.USER_ENWD1}</TableCell>
												<TableCell align="right">{row.USER_ENWD2}</TableCell>
												<TableCell align="right">
													{row.USER_Produktgruppe}
												</TableCell>
												<TableCell align="right">{row.USER_TechnLV}</TableCell>
												<TableCell align="right">
													{row.USER_Werkstoff}
												</TableCell>
											</TableRow>
									  ))
									: ""}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
		</>
	);
};

export default WizardOrder;
