import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField, { textFieldClasses } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { useRecoilState } from "recoil";
import { loadingAtom } from "../atoms/atoms";

import axios from "axios";
import apiConfig from "../api/apiConfig";

const ManualSearchDialog = (props) => {
	const [loading, setLoading] = useRecoilState(loadingAtom);

	const [searchText, setSearchText] = useState();
	const [searchResult, setSearchResult] = useState(null);
	const [searchSelected, setSearchSelected] = useState(null);

	const handleSearchChange = (e) => {
		setSearchText(e.target.value);
	};

	const handleSearchCall = () => {
		setLoading(true);
		const headers = {
			"Api-Key": "inQScFac5we8",
		};

		const request = {
			text: searchText,
		};

		console.log(apiConfig["search"], request);

		axios
			.post(apiConfig["search"], request, {
				headers: headers,
			})
			.then((result) => {
				console.log(result);
				if (result.data) {
					if (Array.isArray(result.data.data.matches)) {
						let items = result.data.data.matches;
						let itemsList = [];

						items.map((item) => {
							let itemObject = {
								index: item.index,
								entries: [],
							};

							Object.values(item.text).map((entrie) => {
								itemObject.entries.push(entrie);
							});

							itemsList.push(itemObject);
						});
						console.log(itemsList);
						setSearchResult(itemsList);
						setLoading(false);
					} else {
						console.log("not passed");
						setLoading(false);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const selectSearchResult = (selected) => {
		setSearchSelected(selected);
	};

	const handleSelectSave = () => {
		if (searchSelected) {
			let selectedItem = searchResult[searchSelected - 1];

			let selectedObject = {
				Artikelnummer: selectedItem.entries[0],
				Bezeichnung1: selectedItem.entries[1],
				Bezeichnung2: selectedItem.entries[2],
				Matchcode: selectedItem.entries[3],
				Specials_dims: selectedItem.entries[4],
				USER_ANSIOD1: selectedItem.entries[5],
				USER_ANSIOD2: selectedItem.entries[6],
				USER_ANSIWD1: selectedItem.entries[7],
				USER_ANSIWD2: selectedItem.entries[8],
				USER_ArtNrTeil1: selectedItem.entries[9],
				USER_ArtNrTeil2: selectedItem.entries[10],
				USER_Attest: selectedItem.entries[11],
				USER_Ausfuehrung: selectedItem.entries[12],
				USER_ENOD1: selectedItem.entries[13],
				USER_ENOD2: selectedItem.entries[14],
				USER_ENWD1: selectedItem.entries[15],
				USER_ENWD2: selectedItem.entries[16],
				USER_Produktgruppe: selectedItem.entries[17],
				USER_TechnLV: selectedItem.entries[18],
				USER_Werkstoff: selectedItem.entries[19],
			};

			setSearchText("");
			setSearchResult(null);

			props.handleSearchAdd(selectedObject);
		}
	};

	return (
		<>
			<Dialog
				open={props.opened}
				onClose={props.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="add-item-search-dialog"
			>
				<DialogTitle id="alert-dialog-title">
					Add new item to an order
				</DialogTitle>
				<DialogContent>
					<div className="add-item-search-input-wrapper">
						<TextField
							id="outlined-basic"
							label="Search"
							variant="outlined"
							onChange={handleSearchChange}
							value={searchText}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment>
										<IconButton onClick={handleSearchCall}>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
					<div className="add-item-search-list-wrapper">
						{searchResult
							? searchResult.map((item, index) => {
									let string = "";
									return (
										<>
											<div
												className={`add-item-list-item-wrapper ${
													searchSelected - 1 == index ? "selected" : ""
												}`}
												onClick={() => selectSearchResult(index + 1)}
											>
												{
													(string +=
														`[${item.index}] ` +
														item.entries
															.filter((entrie) => entrie !== "nan") // Filter out "nan"
															.join(", ")) // Join the entries with a space
												}
											</div>
										</>
									);
							  })
							: ""}
					</div>
				</DialogContent>
				<DialogActions className="add-item-buttons-wrapper">
					<div>
						<Button onClick={props.handleClose} variant="outlined">
							Cancel
						</Button>
						<Button
							onClick={handleSelectSave}
							variant="contained"
							color="success"
							className="process-search-approve-btn"
							disabled={searchSelected ? false : true}
						>
							Add item
						</Button>
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ManualSearchDialog;
