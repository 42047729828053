import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TextField, { textFieldClasses } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";

import WizardOrder from "./WizardOrder";
import WizardResolveMultipleMatches from "./WizardResolveMultipleMatches";
import WizardReviewMatches from "./WizardReviewMatches";

import { useRecoilState } from "recoil";
import { loadingAtom } from "../atoms/atoms";

import axios from "axios";
import apiConfig from "../api/apiConfig";

import { stringSimilarity } from "string-similarity-js";

// import data from "../dump/data.json";
import data from "../dump/data.json";

const fileTypes = ["MSG"];

const UploadWizard = (props) => {
	const [file, setFile] = useState(null);
	const [loading, setLoading] = useRecoilState(loadingAtom);

	const [processBtnDisabled, setProcessBtnDisabled] = useState(false);

	const [apiCallResults, setApiCallResults] = useState(null);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarSeverity, setSnackBarSeverity] = useState("");
	const [snackBarMessage, setSnackBarMessage] = useState("");

	const [wizardStep, setWizardStep] = useState(null);

	const [orderItems, setOrderItems] = useState(null);
	const [inquiryObject, setInquiryObject] = useState(null);
	const [manualAddedMatches, setManuallAddedMatches] = useState([]);

	const handleChange = (file) => {
		setFile(file);
		console.log(file.name);
		// setLoading(true);
	};

	const uploadNewHandler = () => {
		setFile(null);
		// setProcessed(null);
	};

	const processHandler = () => {
		const formData = new FormData();
		formData.append("file", file);

		setProcessBtnDisabled(true);
		setLoading(true);
		const headers = {
			"Api-Key": "inQScFac5we8",
		};

		axios
			.post(apiConfig["process"], formData, {
				headers: headers,
			})
			.then((result) => {
				console.log(result);
				console.log("message items: ", result.data.data.msg_items);
				// decideTheWizardStep(result.data.data);
				const inquiryData = processInquiryTexts(result.data.data);
				setInquiryObject(result.data.data);
				setWizardStep(11);
				setProcessBtnDisabled(false);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setProcessBtnDisabled(false);
				setLoading(false);
			});

		// setLoading(false);
		// // // decideTheWizardStep(data.data);
		// setWizardStep(11);

		// const inquiryData = processInquiryTexts(data.data);
		// // // console.log("result object: ", inquiryData);
		// setInquiryObject(data.data);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	const decideTheWizardStep = (responseData) => {
		let step = 0;

		console.log("decide: ", responseData);

		if (responseData.msg_items || responseData.attachment_matches) {
			step = 1;

			let hasMultipleMatches = false;

			responseData.msg_matches.forEach((match) => {
				if (match.values.length > 1) {
					hasMultipleMatches = true;
				}
			});

			responseData.attachment_matches.forEach((at_match) => {
				if (at_match.values.length > 1) {
					hasMultipleMatches = true;
				}
			});

			if (!hasMultipleMatches) {
				let matchesArray = [];

				if (responseData.attachment_matches.length > 0) {
					responseData.attachment_matches.forEach((at_match) => {
						let tempAtMatch = at_match.values[0].text;
						tempAtMatch.quantity = at_match.details.quantity;
						matchesArray.push(tempAtMatch);
					});
				}

				if (responseData.msg_matches.length > 0) {
					responseData.msg_matches.forEach((match) => {
						let tempMatch = match.values[0].text;
						tempMatch.quantity = match.details.quantity;
						matchesArray.push(tempMatch);
					});
				}

				setOrderItems(matchesArray);
				step = 2;
			}
		}

		setApiCallResults(responseData);
		console.log("step: ", step);
		setWizardStep(step);
	};

	const stepOneCallback = (results) => {
		console.log("step one callback", results, apiCallResults);
		if (results) {
			// const resultsMap = new Map(
			// 	results.map((item) => [item.details.index, item])
			// );

			let tempMatchesArray = [];

			apiCallResults.msg_matches.forEach((callResult, i) => {
				let itesmProcessed = false;
				results.forEach((res, j) => {
					if (callResult.details.index == res.details.index) {
						// tempMatchesArray[i] = {
						// 	values: [res.selected.value],
						// 	details: {
						// 		quantity: res.details.quantity,
						// 	},
						tempMatchesArray[i] = {
							quantity: res.details.quantity,
							Artikelnummer: res.selected.value.Artikelnummer,
							Bezeichnung1: res.selected.value.Bezeichnung1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							Bezeichnung2: res.selected.value.Bezeichnung2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							Matchcode: res.selected.value.Matchcode.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							Specials_dims: res.selected.value.Specials_dims.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ANSIOD1: res.selected.value.USER_ANSIOD1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ANSIOD2: res.selected.value.USER_ANSIOD2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ANSIWD1: res.selected.value.USER_ANSIWD1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ANSIWD2: res.selected.value.USER_ANSIWD2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ArtNrTeil1: res.selected.value.USER_ArtNrTeil1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ArtNrTeil2: res.selected.value.USER_ArtNrTeil2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_Attest: res.selected.value.USER_Attest.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_Ausfuehrung: res.selected.value.USER_Ausfuehrung.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ENOD1: res.selected.value.USER_ENOD1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ENOD2: res.selected.value.USER_ENOD2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ENWD1: res.selected.value.USER_ENWD1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ENWD2: res.selected.value.USER_ENWD2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_Produktgruppe: res.selected.value.USER_Produktgruppe.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_TechnLV: res.selected.value.USER_TechnLV.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_Werkstoff: res.selected.value.USER_Werkstoff.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
						};

						itesmProcessed = true;
					}
				});

				if (!itesmProcessed) {
					// tempMatchesArray[i] = {
					// 	details: {
					// 		quantity: callResult.details.quantity,
					// 	},
					// 	values: [callResult.values[0].text],
					// };

					tempMatchesArray[i] = {
						quantity: callResult.details.quantity,
						Artikelnummer: callResult.values[0].text.Artikelnummer,
						Bezeichnung1: callResult.values[0].text.Bezeichnung1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						Bezeichnung2: callResult.values[0].text.Bezeichnung2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						Matchcode: callResult.values[0].text.Matchcode.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						Specials_dims: callResult.values[0].text.Specials_dims.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ANSIOD1: callResult.values[0].text.USER_ANSIOD1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ANSIOD2: callResult.values[0].text.USER_ANSIOD2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ANSIWD1: callResult.values[0].text.USER_ANSIWD1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ANSIWD2: callResult.values[0].text.USER_ANSIWD2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ArtNrTeil1: callResult.values[0].text.USER_ArtNrTeil1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ArtNrTeil2: callResult.values[0].text.USER_ArtNrTeil2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_Attest: callResult.values[0].text.USER_Attest.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_Ausfuehrung: callResult.values[0].text.USER_Ausfuehrung.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ENOD1: callResult.values[0].text.USER_ENOD1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ENOD2: callResult.values[0].text.USER_ENOD2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ENWD1: callResult.values[0].text.USER_ENWD1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ENWD2: callResult.values[0].text.USER_ENWD2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_Produktgruppe: callResult.values[0].text.USER_Produktgruppe.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_TechnLV: callResult.values[0].text.USER_TechnLV.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_Werkstoff: callResult.values[0].text.USER_Werkstoff.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
					};
				}
			});

			apiCallResults.attachment_matches.forEach((callResult, i) => {
				let itesmProcessed = false;
				results.forEach((res, j) => {
					if (callResult.details.index == res.details.index) {
						// tempMatchesArray[i] = {
						// 	values: [res.selected.value],
						// 	details: {
						// 		quantity: res.details.quantity,
						// 	},
						tempMatchesArray[i] = {
							quantity: res.details.quantity,
							Artikelnummer: res.selected.value.Artikelnummer,
							Bezeichnung1: res.selected.value.Bezeichnung1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							Bezeichnung2: res.selected.value.Bezeichnung2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							Matchcode: res.selected.value.Matchcode.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							Specials_dims: res.selected.value.Specials_dims.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ANSIOD1: res.selected.value.USER_ANSIOD1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ANSIOD2: res.selected.value.USER_ANSIOD2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ANSIWD1: res.selected.value.USER_ANSIWD1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ANSIWD2: res.selected.value.USER_ANSIWD2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ArtNrTeil1: res.selected.value.USER_ArtNrTeil1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ArtNrTeil2: res.selected.value.USER_ArtNrTeil2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_Attest: res.selected.value.USER_Attest.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_Ausfuehrung: res.selected.value.USER_Ausfuehrung.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ENOD1: res.selected.value.USER_ENOD1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ENOD2: res.selected.value.USER_ENOD2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ENWD1: res.selected.value.USER_ENWD1.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_ENWD2: res.selected.value.USER_ENWD2.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_Produktgruppe: res.selected.value.USER_Produktgruppe.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_TechnLV: res.selected.value.USER_TechnLV.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
							USER_Werkstoff: res.selected.value.USER_Werkstoff.replace(
								/,/g,
								"."
							).replace(/"/g, "'"),
						};

						itesmProcessed = true;
					}
				});

				if (!itesmProcessed) {
					// tempMatchesArray[i] = {
					// 	details: {
					// 		quantity: callResult.details.quantity,
					// 	},
					// 	values: [callResult.values[0].text],
					// };

					tempMatchesArray[i] = {
						quantity: callResult.details.quantity,
						Artikelnummer: callResult.values[0].text.Artikelnummer,
						Bezeichnung1: callResult.values[0].text.Bezeichnung1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						Bezeichnung2: callResult.values[0].text.Bezeichnung2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						Matchcode: callResult.values[0].text.Matchcode.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						Specials_dims: callResult.values[0].text.Specials_dims.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ANSIOD1: callResult.values[0].text.USER_ANSIOD1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ANSIOD2: callResult.values[0].text.USER_ANSIOD2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ANSIWD1: callResult.values[0].text.USER_ANSIWD1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ANSIWD2: callResult.values[0].text.USER_ANSIWD2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ArtNrTeil1: callResult.values[0].text.USER_ArtNrTeil1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ArtNrTeil2: callResult.values[0].text.USER_ArtNrTeil2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_Attest: callResult.values[0].text.USER_Attest.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_Ausfuehrung: callResult.values[0].text.USER_Ausfuehrung.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ENOD1: callResult.values[0].text.USER_ENOD1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ENOD2: callResult.values[0].text.USER_ENOD2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ENWD1: callResult.values[0].text.USER_ENWD1.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_ENWD2: callResult.values[0].text.USER_ENWD2.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_Produktgruppe: callResult.values[0].text.USER_Produktgruppe.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_TechnLV: callResult.values[0].text.USER_TechnLV.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
						USER_Werkstoff: callResult.values[0].text.USER_Werkstoff.replace(
							/,/g,
							"."
						).replace(/"/g, "'"),
					};
				}
			});

			setOrderItems(tempMatchesArray);
			setWizardStep(2);

			console.log("matched matches: ", tempMatchesArray);
		}
	};

	const processInquiryTexts = (inquiryData) => {
		// console.log("text process function: ", inquiryData);
		let inquiryObject = {
			messageText: "",
			attachmentsText: "",
			// identifiedItems: inquiryData.msg_matches,
		};

		if (inquiryData.msg_text) {
			inquiryData.msg_text.forEach((string) => {
				inquiryObject.messageText = inquiryObject.messageText + string;
			});
		}

		if (inquiryData.attachment_text) {
			inquiryData.attachment_text.forEach((string) => {
				inquiryObject.attachmentsText = inquiryObject.attachmentsText + string;
			});
		}

		return inquiryObject;
	};

	const stepZeroCallback = (matchingObject) => {
		console.log("Step 0 callback: ", matchingObject);
		setManuallAddedMatches(matchingObject.manual_matches);
		decideTheWizardStep(matchingObject);
		// setWizardStep(1);
	};

	return (
		<>
			<div style={{ display: props.display ? "block" : "none" }}>
				<div className="header-wrapper">
					<div className="upload-file-wrapper">
						{!file ? (
							<FileUploader
								handleChange={handleChange}
								name="file"
								types={fileTypes}
							/>
						) : (
							<div className="upload-file-feedback-wrapper">
								<Card sx={{ minWidth: 275 }}>
									<CardContent>
										File{" "}
										<span className="file-upload-feedback-uploaded-name">
											{file ? file.name : ""}
										</span>{" "}
										uploaded
										<Button
											className="file-upload-feedback-upload-new-btn"
											size="small"
											onClick={uploadNewHandler}
										>
											Upload new file
										</Button>
										<div className="file-upload-feedback-process-wrapper">
											<Button
												variant="contained"
												color="success"
												onClick={processHandler}
												disabled={processBtnDisabled}
												hidden={processBtnDisabled}
											>
												<KeyboardDoubleArrowRightIcon />
												Process
											</Button>
										</div>
									</CardContent>
								</Card>
								<div>
									<Snackbar
										open={snackBarOpen}
										autoHideDuration={6000}
										onClose={handleSnackBarClose}
									>
										<MuiAlert
											onClose={handleSnackBarClose}
											severity={snackBarSeverity}
											elevation={6}
											variant="filled"
										>
											{snackBarMessage}
										</MuiAlert>
									</Snackbar>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="processed-body-wrapper row" id="processed-body">
					<div className="process-body-wizard-wrapper">
						{wizardStep == 11 && inquiryObject ? (
							<WizardReviewMatches
								data={inquiryObject}
								callback={stepZeroCallback}
							/>
						) : (
							""
						)}
						{wizardStep == 1 && apiCallResults ? (
							<WizardResolveMultipleMatches
								data={apiCallResults}
								callback={stepOneCallback}
							/>
						) : (
							""
						)}
						{wizardStep == 2 && orderItems ? (
							<WizardOrder
								data={orderItems}
								manualMatches={manualAddedMatches}
							/>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default UploadWizard;
