import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import Button from "@mui/material/Button";

import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import ManualSearchDialog from "./ManualSearchDialog";

// import ExcelExport from "./ExportToExcel";

const WizardReviewMatches = (props) => {
	const [] = useState();

	const [manualDialogOpen, setManualDialogOpen] = useState(false);
	const [selectedManualArray, setSelectedManualArray] = useState([]);

	useEffect(() => {
		console.log("props: ", props.data);
	}, []);

	const handleDialogManualOpen = () => {
		setManualDialogOpen(true);
	};

	const handleCloseManualSearch = () => {
		setManualDialogOpen(false);
	};

	const handleDialogSearchAdd = (result) => {
		console.log("callback: ", result);
		let temArray = selectedManualArray;
		temArray.push(result);
		setSelectedManualArray(temArray);
		setManualDialogOpen(false);
	};

	const handleStepChange = () => {
		let data = props.data;
		data.manual_matches = selectedManualArray;
		console.log("step change: ", data);
		props.callback(data);
	};

	return (
		<>
			<div className="processed-body-wrapper row" id="processed-body">
				<div className="col-md-6 processed-column text-column">
					<h3 className="processed-text-header">Message text</h3>
					<div className="processed-text-text-wrapper">
						{props.data
							? props.data.msg_text.map((string) => {
									return <p>{string}</p>;
							  })
							: ""}
					</div>
				</div>
				<div className="col-md-6 processed-column result column text-column">
					<h3 className="processed-text-header">
						Identified items
						<Button
							className="file-upload-feedback-upload-new-btn margin-left-20"
							size="small"
							variant="contained"
							onClick={handleDialogManualOpen}
						>
							Add via search
						</Button>
						<Button
							onClick={handleStepChange}
							variant="contained"
							color="success"
							className="process-search-approve-btn process-btn-next"
							endIcon={<KeyboardDoubleArrowRightIcon />}
						>
							Next step
						</Button>
					</h3>
					<div className="processed-text-text-wrapper processed-text-matches-wrapper">
						{props.data
							? props.data.msg_items.map((match, i) => {
									return (
										<p
											className={`recognized-item-text processed-select-match`}
										>
											{`[${i}] ${match}`}
										</p>
									);
							  })
							: ""}
					</div>
					<h3 className="processed-text-header">Manualy selected items</h3>
					<div className="processed-text-text-wrapper processed-text-matches-wrapper">
						<div className="processed-text-text-wrapper processed-text-matches-wrapper">
							{/* {selectedManualArray
								? selectedManualArray.map((item, i) => {
										return (
											<p className="recognized-item-text processed-select-match">
												{Object.entries(item) // Convert the object to key-value pairs
													.filter(([key, value]) => value !== "nan") // Filter out entries where the value is "nan"
													.map(([key, value]) => `${value}`) // Format as "key: value"
													.join(", ") // Join the key-value pairs with a comma and space
												}
											</p>
										);
								  })
								: ""} */}
							{selectedManualArray && selectedManualArray.length > 0 ? (
								<TableContainer component={Paper}>
									<Table size="small" aria-label="manual matches table">
										<TableHead>
											<TableRow>
												<TableCell>Artikelnummer</TableCell>
												<TableCell align="right">Bezeichnung1</TableCell>
												<TableCell align="right">Bezeichnung2</TableCell>
												<TableCell align="right">Matchcode</TableCell>
												<TableCell align="right">Specials_dims</TableCell>
												<TableCell align="right">USER_ANSIOD1</TableCell>
												<TableCell align="right">USER_ANSIOD2</TableCell>
												<TableCell align="right">USER_ANSIWD1</TableCell>
												<TableCell align="right">USER_ANSIWD2</TableCell>
												<TableCell align="right">USER_ArtNrTeil1</TableCell>
												<TableCell align="right">USER_ArtNrTeil2</TableCell>
												<TableCell align="right">USER_Attest</TableCell>
												<TableCell align="right">USER_Ausfuehrung</TableCell>
												<TableCell align="right">USER_ENOD1</TableCell>
												<TableCell align="right">USER_ENOD2</TableCell>
												<TableCell align="right">USER_ENWD1</TableCell>
												<TableCell align="right">USER_ENWD2</TableCell>
												<TableCell align="right">USER_Produktgruppe</TableCell>
												<TableCell align="right">USER_TechnLV</TableCell>
												<TableCell align="right">USER_Werkstoff</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedManualArray.map((item, i) => (
												<TableRow key={i}>
													<TableCell>{item.Artikelnummer}</TableCell>
													<TableCell align="right">
														{item.Bezeichnung1}
													</TableCell>
													<TableCell align="right">
														{item.Bezeichnung2}
													</TableCell>
													<TableCell align="right">{item.Matchcode}</TableCell>
													<TableCell align="right">
														{item.Specials_dims}
													</TableCell>
													<TableCell align="right">
														{item.USER_ANSIOD1}
													</TableCell>
													<TableCell align="right">
														{item.USER_ANSIOD2}
													</TableCell>
													<TableCell align="right">
														{item.USER_ANSIWD1}
													</TableCell>
													<TableCell align="right">
														{item.USER_ANSIWD2}
													</TableCell>
													<TableCell align="right">
														{item.USER_ArtNrTeil1}
													</TableCell>
													<TableCell align="right">
														{item.USER_ArtNrTeil2}
													</TableCell>
													<TableCell align="right">
														{item.USER_Attest}
													</TableCell>
													<TableCell align="right">
														{item.USER_Ausfuehrung}
													</TableCell>
													<TableCell align="right">{item.USER_ENOD1}</TableCell>
													<TableCell align="right">{item.USER_ENOD2}</TableCell>
													<TableCell align="right">{item.USER_ENWD1}</TableCell>
													<TableCell align="right">{item.USER_ENWD2}</TableCell>
													<TableCell align="right">
														{item.USER_Produktgruppe}
													</TableCell>
													<TableCell align="right">
														{item.USER_TechnLV}
													</TableCell>
													<TableCell align="right">
														{item.USER_Werkstoff}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								""
							)}
						</div>
					</div>
					<ManualSearchDialog
						opened={manualDialogOpen}
						handleClose={handleCloseManualSearch}
						handleSearchAdd={handleDialogSearchAdd}
					/>
				</div>
			</div>
		</>
	);
};

export default WizardReviewMatches;
